<template>
  <div class="acn-public-jobs">
    <div class="container fluid">
      <h1 class="m-4">ACN Public Job</h1>

      <div class="card">
        <TabsIso
          :value="currentTab"
          :tabs="tabs"
          @tabChange="tabChange"
        ></TabsIso>

        <router-view></router-view>
      </div>
      <div class="mt-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TabsIso: () => import("@/components/GlobalComponents/FdTabs/TabsIso")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      tabs: [
        {
          name: "ACNPublicJobs",
          label: "Jobs",
          iconClass: "fas fa-suitcase"
        },
        {
          name: "ACNSpecialistJobs",
          label: "Specialist",
          iconClass: "fas fa-user-tie"
        }
      ]
    };
  },
  computed: {
    currentTab() {
      if (
        this.$route.name === "ACNPublicJobs" ||
        this.$route.name === "ACNSpecialistJobs"
      ) {
        return this.$route.name;
      } else {
        return "ACNPublicJobs";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    tabChange(name) {
      this.$router.push({ name: name });
    }
  }
};
</script>

<style lang="scss"></style>
